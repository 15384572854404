import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import AppDrawer from './AppDrawer';
import Inventory from './Inventory/Inventory';
import { Route, Routes } from 'react-router-dom';
import Users from './Users/Users';

const Admin = () => {
    const [drawer, setDrawer] = useState(false);

    useEffect(()=>{
        console.log(drawer,'admin')
    },[drawer])

  return (
    <>
        <Navbar useDrawer={setDrawer} />
        <AppDrawer drawerState={drawer} useDrawer={setDrawer} />
        <Routes>
            <Route path="inventory"  element={<Inventory/>} />
            <Route path="users"  element={<Users/>} />
        </Routes>
    </>
  )
}

export default Admin