import { Button } from '@mui/material'
import React from 'react'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import InventoryTable from './InventoryTable';



const Inventory = () => {



  return (
    <>
    <div style={{
        padding: 30,
    }}>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <h1> Inventory </h1>
            <div className='headingButtons'>
                <Button variant="contained" startIcon={ <AddRoundedIcon/>}> Add Item</Button>
            </div>
        </div>

        <InventoryTable/>
    </div>

    
    </>
    
  )
}

export default Inventory