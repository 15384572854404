import './App.css';
import Login from './components/login';
import Admin from './components/admin/Admin';
import {  Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  palette:{
    primary: {
      main: "#003773",
    },
    secondary:{
      main: "#FF004C"
    },
    action:{
      main: "pink"
    },
    icons:{
      main: "var(--grey)"
    }
  }
})

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        
      <Routes>
        <Route path='/' element={ <Login/> } />
        <Route path='/admin/*' element={ <Admin/> } />
      </Routes>
      
      </ThemeProvider>
    </div>
  );
}

export default App;
