import React, { useEffect, useState } from 'react';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import  Search  from '@mui/icons-material/Search';
import  AppBar  from '@mui/material/AppBar';
import  Badge  from '@mui/material/Badge';
import  Box  from '@mui/material/Box';
import  IconButton  from '@mui/material/IconButton';
import  InputAdornment  from '@mui/material/InputAdornment';
import  TextField  from '@mui/material/TextField';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const Navbar = (props) => {

    // const [btnState, setBtnState] = useState(false)
    
    // useEffect(()=>{
    //     props.useDrawer(btnState)
    // }, [btnState])

    return (
      <div> 
          <div style={{
              padding: "16px 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background:"var(--blue)"
          }}>
              <Box>
                  <IconButton onClick={()=>{props.useDrawer(true)}} sx={{ width: 40, height: 40, marginRight: 0 }} size='medium'>
                      <MenuRoundedIcon  color="icons" fontSize='inherit'/>
                  </IconButton>
                  <TextField color='secondary' sx={{ 
                      "& .MuiInputBase-input":{ padding: "10px 0" },
                      "& .MuiOutlinedInput-notchedOutline":{border: "1px solid secondary"}
                  }} id="outlined-basic" placeholder='Search' variant="outlined" InputProps={{startAdornment:(
                      <InputAdornment position='start'>
                          <Search color='icons' />
                      </InputAdornment>
                  )
                  }} />
              </Box>
              <Box>
                  <IconButton>
                      <Badge badgeContent={4} >
                          <NotificationsIcon color='icons'/>
                      </Badge>
                  </IconButton>
                  <IconButton>
                      <AccountCircleIcon color='icons' />
                  </IconButton>
              </Box>
          </div>
      </div>
    )
  }

  export default Navbar
