import React from 'react'
import { DataGrid } from '@mui/x-data-grid';

const InventoryTable = () => {

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'product', headerName: 'Product', width: 130 },
        { field: 'price', headerName: 'Price', width: 130 },
        { field: 'stock', headerName: 'Stock', type: 'number', width: 90 }
      ];

      const rows = [
        { id: 1, product: 'Khamere Roti', price: "$10", stock: 20 },
        { id: 2, product: 'Butter Chicken', price: "$100", stock: 17 },
        { id: 3, product: 'Coffee', price: "$1000", stock: 19 },
        { id: 4, product: 'Pizza', price: "$69", stock: 144 },
        { id: 5, product: 'Burger', price: "$123", stock: 2000 },
        { id: 6, product: 'Something else', price: "$1230", stock: 2220 },
      ];

  return (
    <>
        <DataGrid
            style={{
                marginTop:"20px"
            }}
            rows={rows}
            columns={columns}
            initialState={{
                pagination: {
                paginationModel: { page: 0, pageSize: 10 },
                },
            }}
            // pageSizeOptions={[5, 10]}
            checkboxSelection
        />
    </>
  )
}

export default InventoryTable