import React, { useEffect, useState } from 'react'
import './login.css'
import loading from './loading.svg'

const Login = () => {

    const [btn, setBtn] = useState('Submit');
    const [loadingState, handleLoading] = useState({display:'none'});
    console.log(process.env.THIS_KEY);

    const handleFetchData = (name, pass) => {
        // button loading
        setBtn( prev => "" );
        handleLoading({display:'block'});

        // fetch api
        fetch('http://localhost:8080/api/v1/user/login', {
            method:'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userEmail: name,
                userPassword: pass
            })
        })
        .then((response)=>{
            if (response.ok) {
                alert('Login successful!');
            } else {
                alert('Login failed. Please check your email and password.');
            }

            // button loading stop
            setBtn( prev => "Submit" );
            handleLoading({display:'none'});
        })
        .catch(error => console.log(error));
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        handleFetchData(e.target.userEmail.value, e.target.userPassword.value);
    }

  return (
    <div className='loginWrapper'>
        <div className='loginCard'>
            <h2> Login </h2>
            <form onSubmit={handleSubmit}>
                <div className='username'>
                    <label> Email </label>
                    <br/>
                    <input type='email' name='userEmail' />
                </div>
                <div className='password'>
                    <label> Password </label>
                    <br/>
                    <input type='password' name='userPassword'/>
                </div>

                <button className='mainBtn' type='submit'> 
                {btn} 
                <img style={loadingState} src={loading} />
                </button>
            </form>
        </div>
    </div>
  )
}

export default Login
