import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import InventoryIcon from '@mui/icons-material/Inventory';
import GroupIcon from '@mui/icons-material/Group';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link } from 'react-router-dom';

const AppDrawer = (props) => {
    const [open, setOpen] = useState(false)

    useEffect(()=>{
        setOpen(props.drawerState);
        console.log(props.drawerState, 'inside, app drawer')
    },[props.drawerState])

    const DrawerList = (
      <Box sx={{ width: 250 }} role="presentation" onClick={
        ()=>{
            setOpen(false)
            props.useDrawer(false)    
        }} >
        <List>
            <Link to="/">
                <ListItemButton>
                    <ListItemIcon>
                        <DashboardIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
            </Link>

            <Link to="/admin/inventory">
            
            <ListItemButton>
                <ListItemIcon>
                    <InventoryIcon/>
                </ListItemIcon>
                <ListItemText primary="Inventory" />
            </ListItemButton>
            
            </Link>

            <Link to="/admin/users">

            <ListItemButton>
                <ListItemIcon>
                    <GroupIcon/>
                </ListItemIcon>
                <ListItemText primary="Users" />
            </ListItemButton>
            
            </Link>

        </List>
      </Box>
    );
  
    return (
      <div>
        <Drawer open={open} onClose={()=>{
            setOpen(false)
            props.useDrawer(false)  
        }}>
          {DrawerList}
        </Drawer>
      </div>
    );
}

export default AppDrawer